@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
    background: rgb(28, 25, 25);
    color: white;
    font-family: "Inter", sans-serif;
}